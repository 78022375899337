<template>
  <v-app>
    <transition name="fade-in-up">
      <div class="kt-portlet">
        <div class="kt-portlet__body kt-portlet__body--fit">
          <div
            class="kt-grid kt-wizard-v3 kt-wizard-v3--white"
            id="kt_wizard_v3"
            data-ktwizard-state="step-first"
          >
            <div class="kt-grid__item">
              <!--begin: Form Wizard Nav -->
              <div class="kt-wizard-v3__nav">
                <div
                  class="kt-wizard-v3__nav-items kt-wizard-v3__nav-items--clickable"
                >
                  <!--doc: Replace A tag with SPAN tag to disable the step link click -->
                  <div
                    class="kt-wizard-v3__nav-item"
                    data-ktwizard-type="step"
                    data-ktwizard-state="current"
                  >
                    <div class="kt-wizard-v3__nav-body">
                      <div class="kt-wizard-v3__nav-label">
                        <span>
                          <i class="flaticon2-folder"></i>
                        </span>
                        Principal
                      </div>
                      <div class="kt-wizard-v3__nav-bar"></div>
                    </div>
                  </div>
                  <div class="kt-wizard-v3__nav-item" data-ktwizard-type="step">
                    <div class="kt-wizard-v3__nav-body">
                      <div class="kt-wizard-v3__nav-label">
                        <span>
                          <i class="flaticon-price-tag"></i>
                        </span>
                        SNL
                      </div>
                      <div class="kt-wizard-v3__nav-bar"></div>
                    </div>
                  </div>
                  <div class="kt-wizard-v3__nav-item" data-ktwizard-type="step">
                    <div class="kt-wizard-v3__nav-body">
                      <div class="kt-wizard-v3__nav-label">
                        <span>
                          <i class="flaticon2-crisp-icons-1"></i>
                        </span>
                        LOU
                      </div>
                      <div class="kt-wizard-v3__nav-bar"></div>
                    </div>
                  </div>
                  <div class="kt-wizard-v3__nav-item" data-ktwizard-type="step">
                    <div class="kt-wizard-v3__nav-body">
                      <div class="kt-wizard-v3__nav-label">
                        <span>
                          <i class="flaticon2-document"></i>
                        </span>
                        Quotations
                      </div>
                      <div class="kt-wizard-v3__nav-bar"></div>
                    </div>
                  </div>
                  <div class="kt-wizard-v3__nav-item" data-ktwizard-type="step">
                    <div class="kt-wizard-v3__nav-body">
                      <div class="kt-wizard-v3__nav-label">
                        <span>
                          <i class="flaticon2-list"></i>
                        </span>
                        Reports
                      </div>
                      <div class="kt-wizard-v3__nav-bar"></div>
                    </div>
                  </div>
                  <div class="kt-wizard-v3__nav-item" data-ktwizard-type="step">
                    <div class="kt-wizard-v3__nav-body">
                      <div class="kt-wizard-v3__nav-label">
                        <span>
                          <i class="flaticon-price-tag"></i>
                        </span>
                        Timelog
                      </div>
                      <div class="kt-wizard-v3__nav-bar"></div>
                    </div>
                  </div>
                  <div class="kt-wizard-v3__nav-item" data-ktwizard-type="step">
                    <div class="kt-wizard-v3__nav-body">
                      <div class="kt-wizard-v3__nav-label">
                        <span>
                          <i class="flaticon-confetti"></i>
                        </span>
                        Extras
                      </div>
                      <div class="kt-wizard-v3__nav-bar"></div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end: Form Wizard Nav -->
            </div>
            <div
              class="kt-grid__item kt-grid__item--fluid kt-wizard-v3__wrapper"
            >
              <!--begin: Form Wizard Form-->
              <form class="kt-form" id="kt_form">
                <!--begin: Principal-->
                <div
                  class="kt-wizard-v3__content"
                  data-ktwizard-type="step-content"
                  data-ktwizard-state="current"
                >
                  <div class="kt-heading kt-heading--md">
                    Work Order {{ currentId }}
                  </div>
                  <div class="kt-form__section kt-form__section--first">
                    <div class="kt-wizard-v2__form">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="form-group">
                            <v-autocomplete
                              placeholder="Select Handler"
                              :items="handlerList"
                              item-text="name"
                              item-value="id"
                              label="Handler"
                              v-model="currentItem.handler"
                              :disabled="blockHanlderList"
                            ></v-autocomplete>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <v-menu
                              v-model="popDatepick0"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  label="Date"
                                  prepend-icon="event"
                                  readonly
                                  v-on="on"
                                  required
                                  v-model="currentItem.date"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="currentItem.date"
                                @input="popDatepick0 = false"
                              ></v-date-picker>
                            </v-menu>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <v-menu
                              v-model="popDatepick1"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  label="Date of Claim"
                                  prepend-icon="event"
                                  readonly
                                  v-on="on"
                                  v-model="currentItem.claim_date"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="currentItem.claim_date"
                                @input="popDatepick1 = false"
                              ></v-date-picker>
                            </v-menu>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <v-text-field
                              label="Port"
                              name="port_label"
                              type="text"
                              v-model="currentItem.port_label"
                              ></v-text-field>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <v-text-field
                              label="Vessel"
                              name="vessel"
                              type="text"
                              required
                              v-model="currentItem.vessel"
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <v-text-field
                              label="Ex Vessel"
                              name="ex_vessel"
                              type="text"
                              v-model="currentItem.exvessel"
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <v-text-field
                              label="Voyage"
                              name="voyage"
                              type="text"
                              v-model="currentItem.voyage"
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <v-text-field
                              label="Ex Voyage"
                              name="ex_oyage"
                              type="text"
                              v-model="currentItem.exvoyage"
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <v-textarea
                              autocomplete="Description"
                              label="Description"
                              rows="3"
                              required
                              v-model="currentItem.description"
                            ></v-textarea>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <v-autocomplete
                              placeholder="Select Agent"
                              :items="agentList"
                              item-text="name"
                              item-value="id"
                              label="Agent"
                              v-model="currentItem.agent"
                            ></v-autocomplete>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <v-autocomplete
                              placeholder="Select Client"
                              :items="clientList"
                              :search-input.sync="currentItem.client_label"
                              item-text="name"
                              item-value="id"
                              label="Client"
                              v-model="currentItem.client"
                            ></v-autocomplete>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <v-text-field
                              label="Client Reference"
                              name="client_reference"
                              type="text"
                              v-model="currentItem.client_reference"
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <v-autocomplete
                              :items="[
                                'Owner',
                                'Charter',
                                'Disponer Owner',
                                'Time Charter',
                                'Voyage Charter',
                                'Cargo Interests',
                              ]"
                              label="Legal Status"
                              v-model="currentItem.legal_status"
                            ></v-autocomplete>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <v-text-field
                              label="Undewritters"
                              name="undewritters"
                              type="text"
                              v-model="currentItem.undewritters"
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <v-select
                              value="Argentina"
                              label="Country"
                              :items="[
                                'Argentina',
                                'Uruguay',
                                'Paraguay',
                                'Brasil',
                              ]"
                              clearable
                              v-model="currentItem.country"
                            ></v-select>
                          </div>
                        </div>
                        <div class="col-md-5">
                          <div class="form-group">
                            <v-autocomplete
                              placeholder="Select Lawyer"
                              :items="lawyerList"
                              item-text="name"
                              item-value="id"
                              label="Lawyer"
                              v-model="currentItem.lawyer"
                            ></v-autocomplete>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <v-textarea
                              autocomplete="Billing Instructions"
                              label="Billing Instructions"
                              rows="6"
                              v-model="currentItem.billing_instructions"
                            ></v-textarea>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <v-textarea
                              autocomplete="Notes"
                              label="Notes"
                              rows="6"
                              v-model="currentItem.notes"
                            ></v-textarea>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <v-menu
                              v-model="popDatepick2"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  label="Notice Date"
                                  prepend-icon="event"
                                  readonly
                                  v-on="on"
                                  v-model="currentItem.notice_date"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="currentItem.notice_date"
                                @input="popDatepick2 = false"
                              ></v-date-picker>
                            </v-menu>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <v-menu
                              v-model="popDatepick3"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  label="Closed Date"
                                  prepend-icon="event"
                                  readonly
                                  v-on="on"
                                  v-model="currentItem.closed_date"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="currentItem.closed_date"
                                @input="popDatepick3 = false"
                              ></v-date-picker>
                            </v-menu>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <v-switch
                            inset
                            label="Scanned"
                            v-model="currentItem.scanned"
                            :value="currentItem.scanned == '0'"
                          ></v-switch>
                        </div>
                        <div class="col-md-3">
                          <v-switch
                            inset
                            label="Closed"
                            v-model="currentItem.closed"
                            :value="currentItem.closed == '0'"
                            color="red"
                          ></v-switch>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end: Principal-->

                <!--begin: SNL-->
                <div
                  class="kt-wizard-v3__content"
                  data-ktwizard-type="step-content"
                >
                  <div class="kt-form__section kt-form__section--first">
                    <div class="kt-wizard-v3__form">
                      <NominationsList
                        :headers="headersNominations"
                        :headers_extra="headersNominationsExtras"
                        :items="itemsNominations"
                        :items_extra="itemsNominationsExtras"
                        :current_wo_id="currentId"
                        :current_wo_vessel="currentItem.vessel"
                        :current_wo_voyage="currentItem.voyage"
                        :current_wo_client="currentItem.client_label"
                        :current_wo_legal_status="currentItem.legal_status"
                        @initialize="initialize"
                      ></NominationsList>
                    </div>
                  </div>
                </div>
                <!--end: SNL-->

                <!--begin: LOU-->
                <div
                  class="kt-wizard-v3__content"
                  data-ktwizard-type="step-content"
                >
                  <div class="kt-form__section kt-form__section--first">
                    <div class="kt-wizard-v3__form">
                      <LOUList
                        :headers="headersLou"
                        :items="itemsLou"
                        :current_wo_id="currentId"
                        @initialize="initialize"
                      ></LOUList>
                    </div>
                  </div>
                </div>
                <!--end: LOU-->

                <!--begin: Quotations-->
                <div
                  class="kt-wizard-v3__content"
                  data-ktwizard-type="step-content"
                >
                  <div class="kt-form__section kt-form__section--first">
                    <div class="kt-wizard-v3__form">
                      <QuotationsList
                        :headers="headersQuotations"
                        :headers_extra="headersQuotationsExtras"
                        :items="itemsQuotations"
                        :items_extra="itemsQuotationsExtras"
                        :current_wo_id="currentId"
                        :current_wo_vessel="currentItem.vessel"
                        :current_wo_port="currentItem.port_label"
                        :current_wo_date="currentItem.date"
                        @initialize="initialize"
                      ></QuotationsList>
                    </div>
                  </div>
                </div>
                <!--end: Quotations-->

                <!--begin: Reports-->
                <div
                  class="kt-wizard-v3__content"
                  data-ktwizard-type="step-content"
                >
                  <div class="kt-form__section kt-form__section--first">
                    <div class="kt-wizard-v3__form">
                      <ReportsList
                        :headers="headersReports"
                        :items="itemsReports"
                        :current_wo_id="currentId"
                        @initialize="initialize"
                      ></ReportsList>
                    </div>
                  </div>
                </div>
                <!--end: Reports-->

                <!--begin: Timelog-->
                <div
                  class="kt-wizard-v3__content"
                  data-ktwizard-type="step-content"
                >
                  <div class="kt-form__section kt-form__section--first">
                    <div class="kt-wizard-v3__form">
                      <TimeList
                        :headers="headersTime"
                        :items="itemsTime"
                        data_table_top="true"
                        data_table_footer="true"
                        :sort_desc="false"
                        :current_wo_id="currentId"
                        :current_wo_vessel="currentItem.vessel"
                        :current_wo_date="currentItem.date"
                        :current_wo_port="currentItem.port_label"
                        :current_wo_description="currentItem.description"
                        :current_wo_handler="currentItem.handler"
                        @initialize="initialize"
                        :search_tool="false"
                      ></TimeList>
                    </div>
                  </div>
                </div>
                <!--end: Timelog-->

                <!--begin: Extras-->
                <div
                  class="kt-wizard-v3__content"
                  data-ktwizard-type="step-content"
                >
                  <div class="kt-form__section kt-form__section--first">
                    <div class="kt-wizard-v3__review">
                      <div class="kt-wizard-v3__review-item">
                        <div class="kt-wizard-v3__review-content">
                          <ExpensesList
                            data_table_top="true"
                            data_table_footer="true"
                            :headers="headersExpenses"
                            :items="itemsExpenses"
                            :current_wo_id="currentId"
                            @initialize="initialize"
                          ></ExpensesList>
                          <br /><br /><br />

                          <SurveyorsAttendanceList
                            :headers="headersSurveyorsAttendance"
                            :headers_extra="headersSurveyorAttendanceExtras"
                            :items="itemsSurveyorsAttendance"
                            :items_extra="itemsSurveyorAttendanceExtras"
                            @initialize="initialize"
                            :current_wo_id="currentId"
                            :current_wo_vessel="currentItem.vessel"
                            :current_wo_voyage="currentItem.voyage"
                            :search_tool="false"
                          ></SurveyorsAttendanceList>

                          <br /><br /><br />

                          <InvoicesList
                            :headers="headersInvoices"
                            :items="itemsInvoices"
                            @initialize="initialize"
                            data_table_top="true"
                            data_table_footer="true"
                            :current_wo_id="currentId"
                          ></InvoicesList>

                          <br /><br /><br />

                          <ProformasList
                            :headers="headersProformas"
                            :headers_extra="headersProformasExtras"
                            :items="itemsProformas"
                            :items_extra="itemsProformasExtras"
                            @initialize="initialize"
                            :current_wo_id="currentId"
                            :current_wo_vessel="currentItem.vessel"
                            :current_wo_port="currentItem.port_label"
                          ></ProformasList>

                          <br /><br /><br />

                          <BLList
                            :headers="headersBL"
                            :items="itemsBL"
                            @initialize="initialize"
                            :search_tool="false"
                            :current_wo_id="currentId"
                          ></BLList>

                          <br /><br /><br />

                          <ClaimantsList
                            :headers="headersClaimants"
                            :items="itemsClaimants"
                            @initialize="initialize"
                            :current_wo_id="currentId"
                          ></ClaimantsList>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end: Extras-->

                <!--begin: Form Actions -->
                <div class="kt-form__actions">
                  <button
                    class="btn btn-secondary btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
                    type="button"
                    @click.stop.prevent="deleteWO()"
                  >
                    Delete
                  </button>
                  <button
                    class="btn btn-success btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
                    type="submit"
                    @click.stop.prevent="submit()"
                  >
                    Save
                  </button>
                </div>
                <!--end: Form Actions -->
              </form>
              <!--end: Form Wizard Form-->
            </div>
          </div>
        </div>
      </div>
    </transition>
  </v-app>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-3.scss";
</style>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTUtil from "@/assets/js/util";
import KTWizard from "@/assets/js/wizard";
import Swal from "sweetalert2";

import ClaimantsList from "@/views/pages/claimants/ClaimantsList.vue";
import ReportsList from "@/views/pages/reports/ReportsList.vue";
import LOUList from "@/views/pages/lou/LouList.vue";
import ExpensesList from "@/views/pages/expenses/ExpensesList.vue";
import InvoicesList from "@/views/pages/invoices/InvoicesList.vue";
import QuotationsList from "@/views/pages/quotations/QuotationsList.vue";
import NominationsList from "@/views/pages/nominations/NominationsList.vue";
import ProformasList from "@/views/pages/proformas/ProformasList.vue";
import BLList from "@/views/pages/bl/BLList.vue";
import SurveyorsAttendanceList from "@/views/pages/surveyors-attendance/SurveyorsAttendanceList.vue";
import TimeList from "@/views/pages/time/TimeList.vue";
import genericService from "./../../../services/generic.service";
import { mapGetters } from "vuex";

export default {
  components: {
    ClaimantsList,
    ReportsList,
    LOUList,
    ExpensesList,
    InvoicesList,
    QuotationsList,
    NominationsList,
    ProformasList,
    BLList,
    SurveyorsAttendanceList,
    TimeList,
  },

  data: () => ({
    uri: "workorders",
    currentId: "",
    currentTab: "",
    today: new Date().toISOString().substr(0, 10),
    popDatepick0: false,
    popDatepick1: false,
    popDatepick2: false,
    popDatepick3: false,
    date: "2020-02-01",
    claim_date: "2019-08-15",
    notice_date: "2019-09-12",
    closed_date: "2020-05-02",
    scanned: true,

    /* Fields */
    ports: ["Argentina", "Brasil", "Alemania", "China"],

    headersClaimants: [
      { text: "ID", value: "id" },
      { text: "Work Order", value: "work_order" },
      { text: "Company Name", value: "company_label" },
      { text: "Claim", value: "claim" },
      { text: "Amount", value: "amount" },
      { text: "Lou", value: "lou", sortable: true },
      { text: "Actions", value: "actions" },
    ],

    headersReports: [
      { text: "ID", value: "id", sortable: false, filterable: true },
      { text: "Date", value: "date", sortable: false },
      { text: "Description", value: "description", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],

    headersLou: [
      { text: "ID", value: "id", sortable: false },
      { text: "Work Order", value: "work_order", sortable: false },
      { text: "Replaced", value: "replaced", sortable: true },
      { text: "Beneficiary", value: "beneficiary", sortable: false },
      { text: "Amount", value: "amount", sortable: false },
      { text: "Emitted", value: "emited", sortable: false },
      { text: "Cancelled", value: "cancelled", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],

    headersInvoices: [
      { text: "ID", value: "id", sortable: false },
      { text: "Number", value: "number", sortable: false },
      { text: "Date", value: "date", sortable: false },
      { text: "To", sortable: false, value: "company_label", filterable: true },
      { text: "Amount", value: "amount", sortable: false, filterable: true },
      { text: "Paid", value: "paid", sortable: true, filterable: true },
      { text: "Actions", value: "actions", sortable: false },
    ],

    headersExpenses: [
      { text: "ID", value: "id", filterable: true },
      { text: "Company Name", value: "company_label", filterable: true },
      { text: "Date", value: "date" },
      { text: "Surveyor Att.", value: "surveyor_attendance", filterable: true },
      { text: "Paid", value: "paid" },
      { text: "Paid Date", value: "paid_date" },
      { text: "Total", value: "total", filterable: true },
      { text: "Actions", value: "actions" },
    ],

    headersQuotations: [
      { text: "ID", sortable: false, value: "id", filterable: true },
      { text: "WO", value: "work_order", sortable: false, filterable: true },
      { text: "Vessel", value: "vessel", sortable: false, filterable: true },
      { text: "Port", value: "port", sortable: false, filterable: true },
      { text: "To", value: "to", sortable: false, filterable: true },
      { text: "O/B", value: "ob", sortable: false, filterable: true },
      { text: "Date", value: "date", sortable: false },
      { text: "Notes", value: "notes", sortable: false, filterable: true },
      { text: "Actions", value: "actions", sortable: false },
    ],

    headersQuotationsExtras: [
      { text: "ID", value: "id", sortable: true },
      { text: "Description", value: "description", sortable: false },
      { text: "Place", value: "place", sortable: false },
      { text: "QTY", value: "qty", sortable: false },
      { text: "Unit Price", value: "unit_price", sortable: false },
      { text: "Discount", value: "discount", sortable: false },
      { text: "Subtotal", value: "subtotal", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],

    headersNominations: [
      //{ text: "ID", sortable: false, value: "id", filterable: true },
      { text: "NR", sortable: false, value: "nr", filterable: true },
      { text: "WO", value: "work_order", sortable: false, filterable: true },
      { text: "Vessel", value: "vessel", sortable: false, filterable: true },
      { text: "Surveyor", value: "surveyor_label", sortable: false, filterable: true },
      { text: "Client", value: "client", sortable: false, filterable: true },
      {
        text: "Legal Status",
        value: "legal_status",
        sortable: false,
        filterable: true,
      },
      { text: "Date", value: "date", sortable: false },
      {
        text: "Description",
        value: "description",
        sortable: false,
        filterable: true,
      },
      { text: "Actions", value: "actions", sortable: false },
    ],

    headersNominationsExtras: [
      { text: "ID", value: "id", sortable: true },
      { text: "Description", value: "description", sortable: false },
      { text: "Place", value: "place", sortable: false },
      { text: "Agent", value: "agent_label", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],

    headersProformas: [
      { text: "ID", value: "id", sortable: false, filterable: true },
      { text: "WO", value: "work_order", sortable: false, filterable: true },
      { text: "Vessel", value: "vessel", sortable: false, filterable: true },
      { text: "To", value: "to", sortable: false, filterable: true },
      { text: "C/O", value: "co", sortable: false, filterable: true },
      { text: "Date", value: "date", sortable: false },
      { text: "Notes", value: "notes", sortable: false, filterable: true },
      { text: "Actions", value: "actions", sortable: false },
    ],

    headersProformasExtras: [
      { text: "ID", value: "id", sortable: false, filterable: true },
      { text: "Description", value: "description", sortable: false },
      { text: "Place", value: "place", sortable: false },
      { text: "QTY", value: "qty", sortable: false },
      { text: "Unit Price", value: "unit_price", sortable: false },
      { text: "Discount", value: "discount", sortable: false },
      { text: "Subtotal", value: "subtotal", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],

    headersSurveyorAttendanceExtras: [
      { text: "ID", value: "id", sortable: false, filterable: true },
      { text: "Date", value: "date", sortable: false, filterable: true },
      { text: "Type", value: "type", sortable: false, filterable: true },
      { text: "Description", value: "description", sortable: false },
      { text: "Place", value: "place", sortable: false },
      { text: "Timeframe", value: "time", sortable: false },
      { text: "Amount", value: "amount", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],

    headersBL: [
      { text: "ID", value: "id", sortable: true, filterable: true },
      { text: "CNTR", value: "cntr", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],

    headersSurveyorsAttendance: [
      { text: "ID", value: "id", sortable: true, filterable: true },
      {
        text: "Work Order",
        value: "work_order",
        sortable: false,
        filterable: true,
      },
      { text: "Date", value: "date", sortable: false },
      { text: "Vessel", value: "vessel", sortable: false },
      { text: "Voyage", value: "voyage", sortable: false },
      { text: "Aproval", value: "aproval", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],

    headersTime: [
      { text: "ID", value: "id", sortable: true },
      { text: "Date", value: "date", sortable: false },
      {
        text: "Work Order",
        value: "work_order",
        sortable: false,
        filterable: true,
      },
      { text: "Vessel", value: "vessel", sortable: false, filterable: true },
      {
        text: "Description",
        value: "description",
        sortable: false,
        filterable: true,
      },
      {
        text: "Handler",
        align: "start",
        sortable: false,
        value: "handler_label",
        filterable: true,
      },
      { text: "Time", value: "time", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    handlerList: [],
    agentList: [],
    clientList: [],
    lawyerList: [],
    currentItem: {
      handler: "",
      date: "",
      claim_date: "",
      vessel: "",
      exvessel: "",
      voyage: "",
      exvoyage: "",
      description: "",
      agent: "",
      client: "",
      client_label: "",
      port_label: "",
      legal_status: "",
      undewritters: "",
      country: "",
      lawyer: "",
      billing_instructions: "",
      notes: "",
      notice_date: "",
      closed_date: "",
      scanned: "",
      closed: "",
    },

    itemsTime: [],
    itemsClaimants: [],
    itemsReports: [],
    itemsLou: [],
    itemsExpenses: [],
    itemsInvoices: [],
    itemsQuotations: [],
    itemsQuotationsExtras: [],
    itemsNominations: [],
    itemsNominationsExtras: [],
    itemsProformas: [],
    itemsProformasExtras: [],
    itemsSurveyorAttendanceExtras: [],
    itemsBL: [],
    itemsSurveyorsAttendance: [],
    blockHanlderList: false,
  }),

  mounted() {
    var _self = this;

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Work Orders", route: "/work-orders" },
      { title: "WO#" + this.currentId },
    ]);

    // Initialize form wizard
    var wizard = new KTWizard("kt_wizard_v3", {
      startStep: 1, // initial active step number
      clickableSteps: true, // allow step clicking
    });

    this.currentTab = this.$route.params.tab;

    if (this.currentTab != undefined && this.currentTab != "") {
      wizard.goTo(this.currentTab);
    }

    // Validation before going to next page
    wizard.on("beforeNext", function (/*wizardObj*/) {
      // validate the form and use below function to stop the wizard's step
      // wizardObj.stop();
    });

    // Change event
    wizard.on("change", function (/*wizardObj*/) {
      setTimeout(function () {
        KTUtil.scrollTop();
      }, 500);
    });

    genericService.getRecordList(
      "/list/users/" + this.currentUser.role + "/handler",
      function (records) {
        _self.handlerList = records;

        if (
          _self.currentUser.role == "Handler" &&
          _self.currentItem.handler == ""
        ) {
          _self.currentItem.handler = _self.currentUser.id;
          _self.blockHanlderList = true;
        }
      }
    );

    genericService.getRecordList("/list/contacts/Agent", function (records) {
      _self.agentList = records;
    });

    genericService.getRecordList("/list/contacts/Client", function (records) {
      _self.clientList = records;
    });

    genericService.getRecordList("/list/contacts/Lawyer", function (records) {
      _self.lawyerList = records;
    });
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      var _self = this;
      this.currentId = this.$route.params.id;

      genericService.getRecord(this.uri, _self.currentId, function (record) {
        _self.currentItem = record;
      });

      this.getRelatedItems(this.currentId);
    },

    submit: function () {
      var _self = this;

      if (
        this.currentItem.vessel == "" ||
        this.currentItem.handler == "" ||
        this.currentItem.date == "" ||
        this.currentItem.description == ""
      ) {
        Swal.fire({
          title: "",
          text: "Fields Vessel, Handler, Date and Description are mandatories.",
          icon: "warning",
          confirmButtonClass: "btn btn-secondary",
        });
        return;
      }

      genericService.updateRecord(
        this.uri + "/" + _self.currentId,
        _self.currentItem,
        function () {
          Swal.fire({
            title: "",
            text: "Record has been successfully saved!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
          });
        }
      );
    },

    deleteWO() {
      var _self = this;

      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          genericService.deleteItem(_self.uri, _self.currentId);

          Swal.fire({
            title: "Deleted!",
            text: "Your record has been deleted.",
            icon: "success",
          }).then((result) => {
            if (result.value) {
              this.$router.push("/work-orders");
            }
          });
        }
      });
    },

    getRelatedItems(currentId) {
      var _self = this;

      genericService.getRelatedWORecordList(
        "/" + this.uri,
        currentId,
        "lou",
        _self.currentUser.role,
        _self.currentUser.id,
        function (records) {
          _self.itemsLou = records;
        }
      );

      genericService.getRelatedWORecordList(
        "/" + this.uri,
        currentId,
        "timelog",
        _self.currentUser.role,
        _self.currentUser.id,
        function (records) {
          _self.itemsTime = records;
        }
      );

      genericService.getRelatedWORecordList(
        "/" + this.uri,
        currentId,
        "report",
        _self.currentUser.role,
        _self.currentUser.id,
        function (records) {
          _self.itemsReports = records;
        }
      );

      genericService.getRelatedWORecordList(
        "/" + this.uri,
        currentId,
        "expenses",
        _self.currentUser.role,
        _self.currentUser.id,
        function (records) {
          _self.itemsExpenses = records;
        }
      );

      genericService.getRelatedWORecordList(
        "/" + this.uri,
        currentId,
        "nominations",
        _self.currentUser.role,
        _self.currentUser.id,
        function (records) {
          _self.itemsNominations = records;
        }
      );

      genericService.getRelatedWORecordList(
        "/" + this.uri,
        currentId,
        "quotations",
        _self.currentUser.role,
        _self.currentUser.id,
        function (records) {
          _self.itemsQuotations = records;
        }
      );

      if (this.currentUser.role == "Admin") {
        genericService.getRelatedWORecordList(
          "/" + this.uri,
          currentId,
          "proformas",
          _self.currentUser.role,
        _self.currentUser.id,
          function (records) {
            _self.itemsProformas = records;
          }
        );

        genericService.getRelatedWORecordList(
          "/" + this.uri,
          currentId,
          "invoices",
          _self.currentUser.role,
        _self.currentUser.id,
          function (records) {
            _self.itemsInvoices = records;
          }
        );
      }

      genericService.getRelatedWORecordList(
        "/" + this.uri,
        currentId,
        "bl",
        _self.currentUser.role,
        _self.currentUser.id,
        function (records) {
          _self.itemsBL = records;
        }
      );

      genericService.getRelatedWORecordList(
        "/" + this.uri,
        currentId,
        "claimants",
        _self.currentUser.role,
        _self.currentUser.id,
        function (records) {
          _self.itemsClaimants = records;
        }
      );

      genericService.getRelatedWORecordList(
        "/" + this.uri,
        currentId,
        "surveyorattlist",
        _self.currentUser.role,
        _self.currentUser.id,
        function (records) {
          _self.itemsSurveyorsAttendance = records;
        }
      );
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
};
</script>
